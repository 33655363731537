import { getDatabase, ref, push, set } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getDeviceName } from "../helpers";

// Config variables
const API_KEY = process?.env?.REACT_APP_FIREBASE_API_KEY;
const AUTH_DOMAIN = process?.env?.REACT_APP_FIREBASE_AUTH_DOMAIN;
const DATABASE_URL = process?.env?.REACT_APP_FIREBASE_DATABASE_URL;
const PROJECT_ID = process?.env?.REACT_APP_FIREBASE_PROJECT_ID;
const STORAGE_BUCKET = process?.env?.REACT_APP_FIREBASE_STORAGE_BUCKET;
const MESSAGING_SENDER_ID =
  process?.env?.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const APP_ID = process?.env?.REACT_APP_FIREBASE_APP_ID;
const MEASUREMENT_ID = process?.env?.REACT_APP_FIREBASE_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseDB = getDatabase(app);

console.log(app.name, firebaseDB);



export const handleSendDataFireBase = async ({
  offline_id,
  orderId,
  user_detail,
  eventName,
  merchantId,
}) => {
  const { device, os, browser } = getDeviceName();
  console.log(device, os, browser);
  const eventRef = push(ref(firebaseDB));
  const eventId = eventRef.key;
  const customerName = user_detail?.full_name||user_detail?.first_name + " " + user_detail?.last_name;

  // Order data
  const orderData = {
    timeStamp: Date.now() / 1000,
    eventName: eventName,
    userName:customerName,
  };

  if (device?.type) {
    orderData.deviceName = device?.model;
  } else {
    orderData.deviceName = os?.name;
  }
  // if(os?.name){
  //   console.log(2)
  //   orderData.os=os?.name
  // }
  if (browser?.name) {
    orderData.appVersion = browser?.name;
  }

 
  const orderRefPath = `OrderHistory/${merchantId}/${orderId}/${offline_id}/events/${eventId}`;
    console.log()
  try {
    await set(ref(firebaseDB, orderRefPath), orderData);
    console.log(`FireBase data sent successfully! on ${eventName} event path=${orderRefPath}`);
  } catch (error) {
    console.error("Error sending order data:", error);
  }
};

export { firebaseDB, ref, push, set };
